import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  // Navigate,
} from "react-router-dom";
import EventRegistrationPage from "pages/EventRegistration/EventRegistrationPage";
import EventCheckInPage from "pages/EventCheckIn/EventCheckInPage";
import EventDayAward from "pages/EventDayAward/EventDayAward";
import CNYSEFRegistrationPage from "pages/EventRegistration/CNYSEFRegistrationPage";
import ExternalRedirect from "utils/ExternalRedirect";

const AppRoutes = () => {
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          // element={<Navigate to="/cnysef/register" replace={true} />}
          element={<ExternalRedirect url="https://cnysef.most.org" />}
        />
        <Route path="/robotics/checkin" element={<EventCheckInPage />} />
        <Route path="/robotics/awards" element={<EventDayAward />} />
        <Route
          path="/event/:eventID/register"
          element={<EventRegistrationPage />}
        />
        {/* <Route path="/robotics/register" element={<EventRegistrationPage />} /> */}
        <Route path="/cnysef/register" element={<CNYSEFRegistrationPage />} />
        <Route path="*" element={<div>404 Not Found</div>} />
      </Routes>
    </Router>
  );
};

export default AppRoutes;
